<template>
  <section>
    <section>
      <Breadcrumb
        :links="[
          {
            label: 'Back',
            link: true,
            route: (prevRoute && prevRoute.name === 'Instructor Grading Course') 
              ? { name: 'Instructor Grading Course' , params: { id: $route.params.id }, query: { ...prevRoute.query }} 
              : { name: 'Instructor Grading Course' , params: { id: $route.params.id }, query: { search: '', page: 1, paginate: 10 }},
          },
          {
            label: `${title}`,
            link: false,
            route: null,
          },
        ]"
      />
    </section>
    <v-sheet class="custom-border border my-3 pa-3 fade ">
      
      <h3 class="poppins secondary-1--text mb-3">
        Course Reportage Summary
      </h3>
      <iframe 
        src='https://cloud.scorm.com/Reportage/scormreports/widgets/summary/SummaryWidget.php?enc=kznRPiPafOtJdLle3PVmLfC1Dq5YF9%2F%2FvDGLar3koYeuB1rRttvan37xKJQspsztXA%2Fx7FE5MQV84t5axRNfsNwtVmjCWBMmyVEzGIUcErk5SNMDp6BUEQzJaCk0kGx1yrKl5o7jZIkVVCOiKC%2Bi04YcgQuPVRZagunhlFxQDGF31u40Eq4B%2FtVuakO%2Fp%2Fd4gYf57CWfhhsrx1WEU1PR5aFaEgZggel9zmEkE80oKJ%2FgnA8daxEGSN7XpwPq1J%2F9sSKwMZAtRZMHc96w5JFJH1W7nPGV3liLS7lk7n7%2FPWCi4IX0arbEWwxkGFlt5b%2BCAv3oKM1ZRug%3D' 
        frameborder='0' 
        id="myIframe"
        width="100%"
        height="850px"
        scrolling="no" 
      />
    </v-sheet>
    <v-sheet class="custom-border border my-3 pa-3">
      <iframe class="scroller" id="summaryIframe" src='https://cloud.scorm.com/Reportage/scormreports/widgets/ViewAllDetailsWidget.php?enc=sUWoaGRwi8gM50SGPDOf4sgFgLXEX%2FSNDFVNvPxurfEwrXpG%2FedA2HAK%2FNdJ8MUfljx599zb3EQ4lkLK8luLBRHNZVHoXoR7TdR1rKwjnw29wfUTvpMthCWggTClliL8U1VkH9nF5BO%2Bfc733uN1Iln0jfE1vbtAg9cNwpsm6LU3hrVwe7jXtjo0x9zG8tsfQHyutCtdg9EP083AaIrwF1vS5gXCNejD5opPicPFRjydx38y906PcwBPOMvP61luxPpwhoXfFtTjQaZZ2zXL2d5U9N13aQaPR9CdFZ8mEZ1OBMHYjIue9XnJS8dxGlwRQ6a4XPT7syw%3D&standalone=true&embedded=true' scrolling="no" frameborder='0' width="100%" height="350"></iframe> 
    </v-sheet>

  </section>
</template>

<script>
import Breadcrumb from "@/layouts/teacher/InstructorBreadcrumbs.vue";

export default {
  components: {
    Breadcrumb
  },

  data: () => ({
    title: '',
    prevRoute: null,
  }),

  methods: {
    applyStyles() {
      console.log('Applying styles to iframe');

      const iframe = document.getElementById('myIframe');
      try {
        // Try to access the iframe content
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        console.log(iframeDoc.contentWindow);

        // iframeDoc.style.height = iframeDoc.contentWindow.document.body.scrollHeight + 'px';

      } catch (error) {
        console.error("Cannot apply styles due to cross-origin restrictions:", error);
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  }
}
</script>
